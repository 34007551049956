import React, { Component } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBAlert, MDBCardTitle, MDBBox } from 'mdbreact';
import axios from 'axios';
import Loader from 'react-loader-spinner'
import { navigate } from 'gatsby';

function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])\{0}|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default class ContactUs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            name: '',
            phone: '',
            address: '',
            message: '',
            missing: ""
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }
    
    handleSubmit(event) {
        if (this.state.name === "") {
            this.setState({missing: "name"})
            document.getElementById("warningMessage").style.visibility = "visible";
            return 
        }
        if (this.state.email === "" || !validateEmail(this.state.email)) {
            this.setState({missing: "valid email"})
            document.getElementById("warningMessage").style.visibility = "visible";
            return 
        }
        if (this.state.message === "") {
            this.setState({missing: "message"})
            document.getElementById("warningMessage").style.visibility = "visible";
            return 
        }
        document.getElementById("warningMessage").style.visibility = "hidden";
        document.getElementById("formElement").style.display = "none";
        document.getElementById("spinnerElement").style.display = "flex";
        let postItem = this.state
        delete postItem.missing
        axios.post('https://jeo2u9m5tf.execute-api.us-east-2.amazonaws.com/envapp/contact', postItem)
          .then(function (response) {
            if(response.status == 200) {
                document.getElementById("spinnerElement").style.display = "none";
                document.getElementById("successElement").style.display = "flex";
            }
          })
          .catch(function (error) {
            navigate("/error", { state: {error}});
          });
    }

  render() {
    return (
        <MDBContainer className="contactSection">
            <a class="anchor" id="contact"></a>
            <MDBRow>
                <MDBCol md="6" className="contactParent">
                    <form id="formElement" className="formThing">
                    <p className="h5 text-center mb-4">Contact Us Today</p>
                    <div className="grey-text">
                        <MDBInput label="Name*" icon="user" group type="text" validate error="wrong"
                        success="right"  value={this.state.name} onChange={this.handleChange} name="name" id="name" />
                        <MDBInput label="Email*" icon="envelope" group type="email" validate error="wrong"
                        success="right" value={this.state.email} onChange={this.handleChange} name="email" />
                        <MDBInput label="Phone" icon="mobile-alt" group type="number" validate error="wrong"
                        success="right" value={this.state.phone} onChange={this.handleChange} name="phone" />
                        <MDBInput label="Address" icon="map-pin" group type="text" validate error="wrong"
                        success="right" value={this.state.address} onChange={this.handleChange} name="address" />
                        <MDBInput type="textarea" rows="2" label="Your message*" icon="pencil-alt" outline
                        value={this.state.message} onChange={this.handleChange} name="message" />
                    </div>
                    <div className="warning" id="warningMessage">
                        <MDBAlert color="danger" >
                            Please enter your {this.state.missing}
                        </MDBAlert>
                    </div>
                    <div className="text-center">
                        <MDBBtn outline color="green" onClick={this.handleSubmit}>
                        Submit
                        </MDBBtn>
                    </div>
                    </form>
                    <div id="spinnerElement" className="spinner">
                        <Loader type="Oval" color="#264226" height={80} width={80} />
                    </div>
                    <div id="successElement" className="success successOuter">
                        <MDBRow className="testContact" style={{padding: '20px',}}>
                            <MDBIcon icon="thumbs-up" className="greenIcon iconSuc" size="4x" />
                        </MDBRow>
                        <p className="h5 text-center mb-4">Success!</p>
                        <p className="h6 text-center mb-4 grey-text">
                            Thank you for reaching out. We will be in contact shortly.
                        </p>
                    </div>
                </MDBCol>
                <MDBCol className="contactParent">
                    <MDBRow className="testContactOuter">
                        <MDBRow className="testContact">
                            <MDBIcon icon="envelope" className="greenIcon" size="2x" />
                        </MDBRow>
                        <MDBRow className="testContact">
                            <a className="greenIcon" href="mailto:envapp@yahoo.com">envapp@yahoo.com</a>
                        </MDBRow>
                    </MDBRow>
                    <MDBRow className="testContactOuter">
                        <MDBRow className="testContact">
                            <MDBIcon icon="phone" className="greenIcon" size="2x" />
                        </MDBRow>
                        <MDBRow className="testContact">
                            <a className="greenIcon" href="tel:19736359404">(973)-635-9404</a>
                        </MDBRow>
                    </MDBRow>  
                    <MDBRow className="testContactOuter">
                        <MDBRow className="testContact">
                            <MDBIcon icon="map-marker-alt" className="greenIcon" size="2x" />
                        </MDBRow>
                        <MDBRow className="testContact greenIcon">
                            1 Post House Rd, Morristown, NJ 07960
                        </MDBRow>
                    </MDBRow>  

                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
  }
}
